import {
  AppManagerEvent,
  PageActionsEvent,
  PageSettingsTab,
  GfppButtonConfig,
} from './types/manifest';
import {
  PAYMENT_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_MANIFEST_KEY,
  CHALLENGES_LIST_PAGE_MANIFEST_KEY,
  CHALLENGES_PAGE_MANIFEST_KEY,
  CHALLENGE_PAYMENT_PAGE_TYPE,
  CHALLENGE_THANK_YOU_PAGE_TYPE,
  CHALLENGES_LIST_PAGE_TYPE,
  CHALLENGES_LIST_WIDGET_TYPE,
  MA_CHALLENGES_LIST_PAGE_TYPE,
  CHALLENGE_PAGE_TYPE,
} from './app-config';

// https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.pages-manifest
export function getChallengesManifest(translation) {
  return {
    controllersStageData: {
      [CHALLENGE_PAYMENT_PAGE_TYPE]: {
        default: {
          displayName: translation.t('editor.initial.page-name.payment-page'),
          gfpp: {
            desktop: {
              mainAction2: GfppButtonConfig.HIDE,
              iconButtons: {},
            },
            mobile: {
              mainAction2: GfppButtonConfig.HIDE,
              iconButtons: {},
            },
          },
        },
      },
      [CHALLENGE_THANK_YOU_PAGE_TYPE]: {
        default: {
          displayName: translation.t('editor.initial.page-name.thank-you-page'),
        },
      },
      [CHALLENGES_LIST_PAGE_TYPE]: {
        default: {
          displayName: translation.t(
            'editor.initial.page-name.challenge-list-page',
          ),
        },
      },
      [CHALLENGES_LIST_WIDGET_TYPE]: {
        default: {
          displayName: translation.t(
            'editor.initial.page-name.challenge-list-page',
          ),
        },
      },
      [MA_CHALLENGES_LIST_PAGE_TYPE]: {
        default: {
          displayName: translation.t('members-area.title'),
        },
      },
      [CHALLENGE_PAGE_TYPE]: {
        default: {
          displayName: translation.t('editor.initial.page-name.challenge-page'),
        },
      },
    },
    pages: {
      pageActions: {
        default: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
        ],
        [PAYMENT_PAGE_MANIFEST_KEY]: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
        ],
        [THANK_YOU_PAGE_MANIFEST_KEY]: [],
        [CHALLENGES_LIST_PAGE_MANIFEST_KEY]: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
        ],
        [CHALLENGES_PAGE_MANIFEST_KEY]: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
        ],
      },
      pageSettings: {
        default: [
          {
            title: translation.t('challenge.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('challenge.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
        ],
        [PAYMENT_PAGE_MANIFEST_KEY]: [
          {
            title: translation.t('challenge.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
        ],
        [THANK_YOU_PAGE_MANIFEST_KEY]: [],
        [CHALLENGES_LIST_PAGE_MANIFEST_KEY]: [
          {
            title: translation.t('challenge.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('challenge.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
          {
            title: translation.t('challenge.page.seo'),
            type: PageSettingsTab.SEO,
          },
        ],
        [CHALLENGES_PAGE_MANIFEST_KEY]: [
          {
            title: translation.t('challenge.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('challenge.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: translation.t('editor.pages.challenges-pages-tab-name'),
          helpId: '6c8df9e5-1bc0-4b1c-93a7-6f1ea34cefc7',
        },
      },
      applicationActions: {
        default: {
          defaultValues: [
            {
              title: translation.t(
                'editor.manage-my-apps.manage-manage-challenges.cta2',
              ),
              event: AppManagerEvent.OPEN_DASHBOARD,
              icon: 'settingsAction',
            },
            {
              title: translation.t('challenge.page.delete'),
              event: PageActionsEvent.REMOVE,
              icon: 'deleteRadio',
            },
          ],
        },
      },
    },
    appDescriptor: { ...getAppDescriptor(translation.t) },
  };
}

const getAppDescriptor = (translate) => {
  return {
    mainActions: [
      {
        title: translate('editor.app-manager.go-to-dashboard'),
        actionId: AppManagerEvent.OPEN_DASHBOARD,
        icon: 'appManager_settingsAction',
      },
    ],
    customActions: [
      {
        title: translate('editor.manage-my-apps.quick-actions.cta1'),
        actionId: AppManagerEvent.OPEN_CREATE_CHALLENGE,
        icon: 'appManager_addElementsAction',
        type: 'editorActions',
      },
      {
        title: translate('editor.manage-my-apps.quick-actions.cta2'),
        actionId: AppManagerEvent.OPEN_DASHBOARD,
        icon: 'appManager_pagesAction',
        type: 'editorActions',
      },
      {
        title: translate('editor.manage-my-apps.quick-actions.cta3'),
        actionId: AppManagerEvent.OPEN_SP,
        icon: 'appManager_settingsAction',
        type: 'editorActions',
      },
    ],
    defaultActions: {
      learnMoreKB: 'c4b68831-310b-4475-81af-3b7a092ffd89',
    },
  };
};
